<template>
	<div
		class="hero"
		style='background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("/images/cb_inner_pages_default_bg-min.jpg");'
	>
		<div class="block-head container-head--xs">
			<!-- <div><span class="icon-user medium"></span></div> -->
            <div class="image_profile_div jhon">                
                <template v-if="user.photo">
                <img class="image_profile" :src="`${baseURLStatic}` + user.photo" alt="Club de Sastreria Barrington" />
                </template>
                <template v-else>
                <img class="image_profile_empty" src="/images/profile_empty.png" alt="Club de Sastreria Barrington" />
                </template>
            </div>
			<div class="text-left">
				<h1>¡Hola {{ store.state.dataToken.name }}!</h1>
				<h3><strong>{{ store.state.dataToken.typeUserLabel }}</strong></h3>
				<h3>Nro de membresia: {{ store.state.dataToken.codeUser }}</h3>
				<!-- <img src="/images/qr.png" class="img-qr" alt="qr" /> -->

			</div>
		</div>
	</div>
</template>

<script>

import store from "@/store";
import { mapActions, mapState } from "vuex";
import API from "@/api";

export default {
	name: "UserHeader",

    setup() {
        const baseURLStatic = API.defaults.baseURLStatic

        return { store, baseURLStatic, ...mapActions(['getUser']) }
    },
    mounted() {
        this.getUser(store.state.dataToken.id)
        console.log("HEADER")
    },
    computed: {
        ...mapState(['user'])
    }

};
</script>

