<template>
    <main>         
        <loading :active="isLoading"
                 :can-cancel="false"
                 :on-cancel="onCancel"                 
                 :is-full-page="fullPage"/>             
        <UserHeader />
        <section class="section pt-0 my-status">
            <div class="container">
                <p class="breadcrumb"><a href="/"><span class="icon-home-outline"></span></a> / <span>Mi Cuenta</span></p>
                <h2><a href="#" @click="router.go(-1)"><img src="/images/arrowleft.svg" alt="Club de Sastreria Barrington"></a> Mi Cuenta</h2>                                                            
                <!-- <p class="form__success" v-if="formUserAccountUpdate">Sus datos fueron actualizados</p>                 -->
                <p class="form__success" v-if="formChangePasswordSuccessful">Su contraseña fué cambiada</p>                
                <div class="page-content">
                    <div class="tabbed">
                        <input type="radio" id="tab1" name="css-tabs" checked>
                        <input type="radio" id="tab2" name="css-tabs">
                        <ul class="tabs">
                            <li class="tab"><label for="tab1">Información personal</label></li>
                            <li class="tab"><label for="tab2">Cambio de contraseña</label></li>
                        </ul>	
                        <div class="tab-content">	
                            <form action="" class="form container container--xs" enctype="multipart/form-data" @submit.prevent="formUserAccountSubmit">
                                <h3>Información personal</h3>
                                <p>
                                    Recuerda mantener tus datos actualizados nos ayuda a conocerte mejor y brindarte un 
                                    mejor servicio. Recuerda, de completar la totalidad de información de tu pérfil recibiraras un bono 
                                    especial de puntos.
                                </p>
                                <p>
                                    <span>RECUERDA: Al completar todos los campos de tu perfil recibirás: {{ store.state.conf[0].bonus_registration_completed }} puntos 
                                        luego de ser verificados por nuestro sistema.
                                        <br>
                                        Estado:  {{ formUserAccount.bonus_registration_completed_label }}.
                                        <br>
                                        Esta bonificación será asignada una sola vez.
                                    </span>                                        
                                </p>

                                <input type="hidden" >
                                <div class="form__control">
                                    <label class="form__label">Cambiar imagen de mi cuenta:</label>
            
                                    <input type="file" accept="image/*" class="form__field" @change="uploadImage($event)">

                                </div>
                                <p class="form__alert">
                                        
                                </p>
                                
                                <div class="form__control">
                                    <label class="form__label">Nombres :</label>
                                    <input type="text" class="form__field" placeholder="" v-model="formUserAccount.first_name">
                                </div>
                                <p class="form__alert" v-if="formUserAccountErrors.errors.first_name">
                                    {{ formUserAccountErrors.errors.password[0] }}                                        
                                </p>                                
                                <div class="form__control">
                                    <label class="form__label">Apellidos :</label>
                                    <input type="text" class="form__field" placeholder="" v-model="formUserAccount.last_name">
                                </div>
                                <p class="form__alert" v-if="formUserAccountErrors.errors.last_name">
                                    {{ formUserAccountErrors.errors.last_name[0] }}                                        
                                </p>                                
                                <div class="form__control">
                                    <label class="form__label">Fecha de nacimiento :</label>
                                    <!-- <input type="text" class="form__field" placeholder="AAAA-MM-DD" v-model="formUserAccount.birthday" > -->
                                    <Datepicker 
                                        class="form__field"
                                        v-model="formUserAccount.birthday" 
                                        format="YYYY-MM-DD"
                                    />
                                </div>          
                                
                                <p class="form__alert" v-if="formUserAccountErrors.errors.birthday">
                                    {{ formUserAccountErrors.errors.birthday[0] }}                                        
                                </p>                                
                                <div class="form__control">
                                    <label class="form__label">Nacionalidad :</label>
                                    <select class="form__field form__field--select" v-model="formUserAccount.country">
                                        <option value=null>Seleccione un pais</option>
                                        <option v-for="(uc, key) in dataUbigeoCountry" :value="uc.id" :key="key">{{ uc.name }}</option>
                                    </select>
                                </div>
                                <p class="form__alert" v-if="formUserAccountErrors.errors.country">
                                    {{ formUserAccountErrors.errors.country[0] }}                                        
                                </p>                                

                                <div class="form__control">
                                    <label class="form__label form__label--error">Teléfono de contacto :</label>
                                    <div class="form__control__row">
                                        <div>
                                            <select  class="form__field form__field--select">
                                                <option value="51">Perú (51+)</option>
                                                <option value="54">Argentina (54+)</option>
                                                <option value="591">Bolivia (591+)</option>
                                                <option value="56">Chile (56+)</option>                                                
                                            </select>   
                                        </div>
                                        <div>
                                            <input type="tel" class="form__field" v-model="formUserAccount.phone_movil" placeholder="">
                                        </div>
                                        
                                    </div>
                                    <p class="form__alert" v-if="formUserAccountErrors.errors.phone_movil">
                                        {{ formUserAccountErrors.errors.phone_movil[0] }}                                        
                                    </p>                                
                                </div>
                            
                                
                                <div class="form__control">
                                    <label class="form__label form__label--error">Email :</label>
                                    <input type="email" class="form__field" disabled v-model="formUserAccount.email">
                                    <p class="form__alert" v-if="formUserAccountErrors.errors.email">
                                        {{ formUserAccountErrors.errors.email[0] }}                                        
                                    </p>                                
                                        
                                </div>
                                <div class="form__control">
                                    <div class="tooltip_div">
                                        <label class="form__label form__label--error">Dirección para enviar muestrarios :
                                            <span class="icon-help-circle"></span>
                                        </label>
                                        <span class="tooltip">Colocar un texto aquí...</span>                                        
                                    </div>
                                    <textarea class="form__field form__field--textarea" v-model="formUserAccount.address_for_samples">
                                    </textarea>
                                    <p class="form__alert" >
                                        
                                    </p>      
                                    
                                </div>
                                <div class="form__control">
                                    <div class="tooltip_div">
                                        <label class="form__label form__label--error">Modalidad de prestación de servicios :
                                            <span class="icon-help-circle"></span>
                                        </label>
                                        <span class="tooltip">Colocar un texto aquí...</span>
                                    </div>
                                    <select class="form__field form__field--select" v-model="formUserAccount.service_provision_modality">
                                        <option value=null disabled>Seleccione una opción</option>            
                                        <option v-for="(spm, key) in dataServiceProvisionModality" :value="spm.id" :key="key">{{ spm.name }}</option>
                                    </select>
                                    <p class="form__alert" v-if="formUserAccountErrors.errors.service_provision_modality">
                                        {{ formUserAccountErrors.errors.service_provision_modality[0] }}                                        
                                    </p>                                
                                </div>                                
                                    <div class="row">
                                        <label class="form__label">Imágenes de proyectos:</label>
                                        <p>                                                                               
                                            <span><strong>RECUERDA:</strong> Al subir 3 imágenes de tu taller recibirás {{ store.state.conf[0].bonus_point_image_project }} puntos 
                                            luego de ser verificados por nuestro sistema.
                                            <br>
                                            Estado:  {{ formUserAccount.bonus_point_image_project_label }}.
                                            <br>
                                            Esta bonificación será asignada una sola vez.
                                            </span>                                                                                    
                                        </p>
                                        <div class="grid grid--three-columns grid--gap-lg mt-lg">
                                            
                                            <div class="cardx">                                                
                                                <input type="file" accept="image/*" class="form__field" @change="uploadImage($event, 'imageProject', 0)">
                                                
                                                <div class="square-iconx">
                                                    <template v-if="dataUserImageProject[0]">
                                                        <input type="hidden" v-model="dataUserImageProject[0].id">
                                                        
                                                        <img :src="`${baseURLStatic}${dataUserImageProject[0].image}`" alt="Club de Sastreria Barrington">
                                                    </template>
                                                    <!-- <template v-else>
                                                        <img src="/images/icon-card.svg" alt="Club de Sastreria Barrington">
                                                    </template> -->
                                                    
                                                </div>
                                            </div>
                                            <div class="cardx">
                                                
                                                <input type="file" accept="image/*" class="form__field" @change="uploadImage($event, 'imageProject', 1)">
                                                <div class="square-iconx">
                                                    <template v-if="dataUserImageProject[1]">
                                                        <input type="hidden" v-model="dataUserImageProject[1].id">
                                                        
                                                        <img :src="`${baseURLStatic}${dataUserImageProject[1].image}`" alt="Club de Sastreria Barrington">
                                                    </template>
                                                    <!-- <template v-else>
                                                        <img src="/images/icon-card.svg" alt="Club de Sastreria Barrington">
                                                    </template> -->
                                                    
                                                </div>
                                            </div>
                                            <div class="cardx">
                                                
                                                <input type="file" accept="image/*" class="form__field" @change="uploadImage($event, 'imageProject', 2)">
                                                <div class="square-iconx">
                                                    <template v-if="dataUserImageProject[2]">
                                                        
                                                        <input type="hidden" v-model="dataUserImageProject[2].id">
                                                        <img :src="`${baseURLStatic}${dataUserImageProject[2].image}`" alt="Club de Sastreria Barrington">
                                                    </template>
                                                    <!-- <template v-else>
                                                        <img src="/images/icon-card.svg" alt="Club de Sastreria Barrington">
                                                    </template> -->
                                                    
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>
                                    
                                
                                
                                <div class="text-align-center pt-3">
                                    
                                    
                                    <button class="cta" >Guardar</button>
                                </div>                                        
                                
                                
                            </form>      
                        </div>
                        <div class="tab-content">	
                            <form action="" class="form container container--xs" @submit.prevent="formChangePasswordSubmit">
                                
                                <p>
                                    Recuerda mantener tus datos actualizados nos ayuda a conocerte mejor y brindarte un 
                                    mejor servicio. Recuerda, de completar la totalidad de información de tu pérfil recibiraras un bono 
                                    especial de puntos.
                                </p>                                
                                
                                <div class="form__control text-left">
                                    <label class="form__label">Contraseña actual:</label>                                                
                                    <input v-bind:type="[showPasswordCurrent ? 'text' : 'password']" class="form__field input_change_password" placeholder="Ingresa tu contraseña actual" v-model="formChangePassword.password">
                                    <span @click="showPasswordCurrent = !showPasswordCurrent"><i :class="[showPasswordCurrent ? 'bi-eye-fill' : 'bi-eye-slash-fill']"></i></span>

                                </div>
                                <p class="form__alert" v-if="formChangePasswordError.errors.password">
                                    {{ formChangePasswordError.errors.password[0] }}                                        
                                </p>                                
                                <div class="form__control">
                                    <label class="form__label">Nueva Contraseña:</label>                                                
                                    <input v-bind:type="[showPasswordNew1 ? 'text' : 'password']" class="form__field input_change_password" placeholder="Ingresa contraseña nueva" v-model="formChangePassword.password1">
                                    <span @click="showPasswordNew1 = !showPasswordNew1"><i :class="[showPasswordNew1 ? 'bi-eye-fill' : 'bi-eye-slash-fill']"></i></span>
                                </div>
                                <p class="form__alert" v-if="formChangePasswordError.errors.password1">
                                    {{ formChangePasswordError.errors.password1[0] }}
                                </p>
                                <div class="form__control">
                                    <label class="form__label">Nueva Contraseña:</label>            
                                    <input v-bind:type="[showPasswordNew2 ? 'text' : 'password']" class="form__field input_change_password" placeholder="Repita contraseña nueva" v-model="formChangePassword.password2">
                                    <span @click="showPasswordNew2 = !showPasswordNew2"><i :class="[showPasswordNew2 ? 'bi-eye-fill' : 'bi-eye-slash-fill']"></i></span>
                                </div>
                                <p class="form__alert" v-if="formChangePasswordError.errors.password2">
                                    {{ formChangePasswordError.errors.password2[0] }}
                                </p>
                                <p class="form__alert" v-if="formChangePasswordError.errors.non_field_errors">
                                    {{ formChangePasswordError.errors.non_field_errors[0] }}
                                </p>
                                
                                                                       
                                <button class="cta" >Guardar</button>

                            </form>      
                        </div>
                    </div>
                </div>                          
            </div>
        </section>        
    </main>       

</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { ref, computed } from "vue";
import router from "@/router"
import store from "@/store";
import API  from "@/api";
import Datepicker from 'vue3-datepicker'
import Swal from 'sweetalert2'
import LineLevel from "@/components/LineLevel";
import UserHeader from "@/views/private/user/components/UserHeader";
import { mapMutations, mapState, mapActions } from 'vuex';


export default {
    name: 'UserAccount',
    components: {
        UserHeader,
        Datepicker,
        Loading,
    },
    setup(){
        const showPasswordCurrent = ref(false)
        const showPasswordNew1 = ref(false)
        const showPasswordNew2 = ref(false)
        var isLoading = ref(false)
        const fullPage = true
        
        const baseURLStatic = API.defaults.baseURLStatic

        const formUserImageProject = ref([])
        //en caso de ser un array de querer consultarse por indice, definir un objeto vacio dentro del array
        // de no ser asi, se obtendra un  TypeError: Cannot read property 'mi campo' of undefined
        const dataUserImageProject = ref([])
        const formChangePassword = ref({
            password: "",
            password1: "",
            password2: ""
        })
        const formChangePasswordSuccessful = ref(false)           
        const formChangePasswordError = ref({errors:{}})
        
        const formUserAccountErrors = ref({errors:{}})  

        const formUserAccount = ref({});
        const formUserAccountUpdate = ref(false)     
        
        const dataUbigeoCountry = ref([])
        const dataUbigeoCountryError = ref("")
        
        const dataServiceProvisionModality = ref([])
        const dataServiceProvisionModalityError = ref()
        
        const getUserImageProject = () => {
            API.get("/api/user-image-project/user/" + store.state.dataToken.id)
            .then(response => {
                dataUserImageProject.value = response.data
                console.log(response.data, 'UPDATE')
            })
            .catch(error => {
                console.log(error.response.data)
            })
        }        

        const formChangePasswordSubmit = () => {      
            isLoading.value = true      
            //debugger
            API.put("/api/change-password/" + store.state.dataToken.id, formChangePassword.value)
                .then(response => {
                    formChangePasswordSuccessful.value = true
                    isLoading.value = false
                    formChangePassword.value = {}
                    Swal.fire({
                        title: 'Contraseña cambiada',
                        //text: 'Do you want to continue',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                })
                .catch(error => {
                    isLoading.value = false
                    formChangePasswordError.value = error.response.data
                })
        }
                
        const uploadImage = (event, type, index) => {     
            //debugger       
            if (type){
                //debugger
                if (type == "imageProject") {
                    let data = {user: store.state.dataToken.id, image: event.target.files[0]}
                    if(typeof dataUserImageProject.value[index] !== 'undefined') {
                        //debugger
                        let id = dataUserImageProject.value[index].id
                        data['userimageproject_id'] = id
                    }
                    formUserImageProject.value.push(data)
                }
            } else {
                formUserAccount.value.photo = event.target.files[0]            
            }
            
        } 
        
        const formImageProjectUserSave = () => {     
            //debugger                   
            for ( var key in formUserImageProject.value ) {                
                const form_data = new FormData();        
                //debugger
                form_data.append("user", formUserImageProject.value[key].user);
                form_data.append("image", formUserImageProject.value[key].image);                
                form_data.append("userimageproject_id", formUserImageProject.value[key].userimageproject_id);                
                API.post("/api/user-image-project", form_data)
                    .then(response => {
                        console.log('created!')
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    })
            }
            formUserImageProject.value = []
        }        
        
        const formUserAccountSubmit = async () => {
            isLoading.value = true
            await formImageProjectUserSave()
            console.log('A')
            
            const form_data = new FormData();        

            if (formUserAccount.value.birthday) {
                formUserAccount.value.birthday = formUserAccount.value.birthday.toISOString().split('T')[0]
            }

            for ( var key in formUserAccount.value ) {
                form_data.append(key, formUserAccount.value[key]);
            }            
            await getUserImageProject()
            console.log('B')
            API.put("/api/user/" + store.state.dataToken.id, form_data)                
                .then(response => {
                    isLoading.value = false
                    store.commit("setStateUser", response.data) 
                    formUserAccount.value = response.data
                    if (formUserAccount.value.birthday) {
                        let arrayBirthday;
                        arrayBirthday = response.data.birthday.split('-')
                        formUserAccount.value.birthday = new Date(parseInt(arrayBirthday[0]), parseInt(arrayBirthday[1]) - 1 , parseInt(arrayBirthday[2]))
                    }                    
                    formUserAccountUpdate.value = true
                    getUserImageProject()                    
                    Swal.fire({
                        title: 'Datos actualizados',                        
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })
                })
                .catch(error => {
                    isLoading.value = false
                    formUserAccountUpdate.value = false
                    
                    formUserAccountErrors.value = error.response.data
                })

            store.dispatch("getUser", store.state.dataToken.id)
            console.log('dispatch!')
        }   

        const getUserAccount = () => {    
            
            API.get("/api/user/" + store.state.dataToken.id)
            .then(
                response => {                    
                    formUserAccount.value = response.data
                    if (response.data.birthday) {                        
                        let arrayBirthday;
                        arrayBirthday = response.data.birthday.split('-')
                        formUserAccount.value.birthday = new Date(parseInt(arrayBirthday[0]), parseInt(arrayBirthday[1]) - 1, parseInt(arrayBirthday[2]))                        
                    }
                    if (response.data.country) {
                        formUserAccount.value.country = response.data.country.id    
                    }                                                     
                }
            )
            .catch(error => {      
                //debugger          
                //formUserAccountErrors.value = error.response.data;    
                console.log(error.response, 'error!')        
            });
        }
        
        const getUserUbigeoCountry = () => {
            API.get("/api/ubigeo/country")
                .then(response => {
                    dataUbigeoCountry.value = response.data
                })
                .catch(error => {
                    dataUbigeoCountryError.value = error.response.data
                })
        }
        const getServiceProvisionModality = () => {
            API.get("/api/service-provision-modality")
                .then(response => {
                    dataServiceProvisionModality.value = response.data
                })
                .catch(error => {
                    dataServiceProvisionModalityError.value = error.response.data
                })
        }

        return {                        
            isLoading,
            fullPage,     
            baseURLStatic,
            router,       
            formUserAccount, 
            formUserAccountErrors, 
            getUserAccount, 
            getUserUbigeoCountry, 
            dataUbigeoCountry,
            dataUbigeoCountryError,
            dataUserImageProject,
            getUserImageProject,
            getServiceProvisionModality,
            dataServiceProvisionModality,
            dataServiceProvisionModalityError,
            formUserAccountSubmit,
            formUserAccountUpdate,
            uploadImage,
            formChangePasswordSuccessful,
            formChangePassword,
            formChangePasswordError,
            formChangePasswordSubmit,
            ...mapActions(['getConf']), 
            store,
            showPasswordCurrent,
            showPasswordNew1,
            showPasswordNew2,
        }

    },

    async mounted(){
        //await this.getUser(store.state.dataToken.id);
        this.getUserAccount();
        this.getUserUbigeoCountry();
        this.getServiceProvisionModality();
        this.getUserImageProject()
        this.getConf();
        //this.getAssetForExchange();
        
    },
    computed: {        
        //...mapState(['user']),        
    }

    
}
</script>


<style scoped>
    
.page-content {
	max-width: 700px; 
	margin: 32px auto; 
	/* padding: 32px;  */
	background: #fff;
}
a {
	color: #21D4FD; 
	transition: all 0.3s;
}
a:hover {
	color: #B721FF;
}

.tabbed {
	overflow-x: hidden; /* so we could easily hide the radio inputs */
	margin: 32px 0;
	padding-bottom: 16px;
	border-bottom: 1px solid #ccc;
}

.tabbed [type="radio"] {
	/* hiding the inputs */
	display: none;
}

.tabs {
	display: flex;
	align-items: stretch;
	list-style: none;
	padding: 0;
	border-bottom: 1px solid #ccc;
}
.tab > label {
	display: block;
	margin-bottom: -1px;
	padding: 12px 15px;
	border: 1px solid #ccc;
	background: #eee;
	color: #666;
	font-size: 12px; 
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 1px;
	cursor: pointer;	
	transition: all 0.3s;
}
.tab:hover label {
	border-top-color: #333;
	color: #333;
}

.tab-content {
	display: none;
	color: #777;
}

/* As we cannot replace the numbers with variables or calls to element properties, the number of this selector parts is our tab count limit */
.tabbed [type="radio"]:nth-of-type(1):checked ~ .tabs .tab:nth-of-type(1) label,
.tabbed [type="radio"]:nth-of-type(2):checked ~ .tabs .tab:nth-of-type(2) label,
.tabbed [type="radio"]:nth-of-type(3):checked ~ .tabs .tab:nth-of-type(3) label,
.tabbed [type="radio"]:nth-of-type(4):checked ~ .tabs .tab:nth-of-type(4) label,
.tabbed [type="radio"]:nth-of-type(5):checked ~ .tabs .tab:nth-of-type(5) label {
	border-bottom-color: #fff;
	/* border-top-color: #B721FF; */
	background: #fff;
	color: #222;
}

.tabbed [type="radio"]:nth-of-type(1):checked ~ .tab-content:nth-of-type(1),
.tabbed [type="radio"]:nth-of-type(2):checked ~ .tab-content:nth-of-type(2),
.tabbed [type="radio"]:nth-of-type(3):checked ~ .tab-content:nth-of-type(3),
.tabbed [type="radio"]:nth-of-type(4):checked ~ .tab-content:nth-of-type(4) {
	display: block;
}
</style>